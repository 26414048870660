<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.bankAccounts") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
        <div class="w-100 d-flex align-center f-wrap  justify-center">
            <span class="align-items-center align-self-center pr-2 mb-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="mode ? 'price__day' : 'price__night blue__night'">
                    {{ $t('message.total_amount') }}: {{ total_amount | formatMoney(2) }} 
                </span>
            </span>
            <span class="align-items-center align-self-center pr-2 mb-2">
                <span class="el-tag el-tag--light custom-font-size-16 clor-red-on-dept" :class="[mode ? 'price__day' : 'price__night']">
                    {{ $t('message.outgoing_money') }}: {{ total_outgoing | formatMoney(2) }} 
                </span>
            </span>
            <span class="align-items-center align-self-center pr-2 mb-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="[(mode ? 'price__day' : 'price__night'), (((total_amount - total_outgoing) < 0) ? 'clor-red-on-dept' : 'green__night' )]">
                    {{ $t('message.remainder') }}: {{ (total_amount - total_outgoing) | formatMoney(2) }} 
                </span>
            </span>
        </div>
      <div class="card-table-header table-crm-smart">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>
                <th v-if="columns.money_amount.show">
                    {{ columns.money_amount.title }}
                </th>
                <th v-if="columns.outgoing_money.show">
                    {{ columns.outgoing_money.title }}
                </th>
                <th v-if="columns.remainder.show">
                    {{ columns.remainder.title }}
                </th>
                <th v-if="columns.currency_id.show">
                    {{ columns.currency_id.title }}
                </th>
                <th v-if="columns.number.show">
                    {{ columns.number.title }}
                </th>
                <th v-if="columns.is_active.show">
                    {{ columns.is_active.title }}
                </th>
                <th v-if="columns.mfo.show">
                    {{ columns.mfo.title }}
                </th>
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>
              <th v-if="columns.money_amount.show">
                  <crm-input
                    disabled
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.money_amount"
                  ></crm-input>
              </th>
              <th v-if="columns.outgoing_money.show">
                  <crm-input
                    disabled
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.outgoing_money"
                  ></crm-input>
              </th>
              <th v-if="columns.remainder.show">
                  <crm-input
                    disabled
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.remainder"
                  ></crm-input>
              </th>

              <th v-if="columns.currency_id.show">
                <select-currency
                    :size="'mini'"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :placeholder="columns.currency_id.title"
                    :id="filterForm.currency_id"
                    v-model="filterForm.currency_id"
                    >
                </select-currency>
              </th>

              <th v-if="columns.number.show">
                  <crm-input
                      :placeholder="columns.number.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.number"
                  ></crm-input>
              </th>

              <th v-if="columns.is_active.show">
                    
              </th>

              <th v-if="columns.mfo.show">
                  <crm-input
                      :placeholder="columns.mfo.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.mfo"
                  ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="bankAccount in list" :key="bankAccount.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ bankAccount.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ bankAccount.name }}
                </td>

                <td v-if="columns.money_amount.show">
                    <span class="bold__money">{{ bankAccount.money_amount | formatNumber(2) }}</span>
                </td>

                <td v-if="columns.outgoing_money.show">
                    <span class="bold__money">{{ bankAccount.outgoing_money | formatNumber(2) }}</span>
                </td>

                <td v-if="columns.remainder.show">
                    <span class="bold__money" :class="((bankAccount.money_amount - bankAccount.outgoing_money) < 0) ? 'clor-red-on-dept' : ''">{{ (bankAccount.money_amount - bankAccount.outgoing_money) | formatNumber(2)}}</span>
                </td>

                <td v-if="columns.currency_id.show">
                    {{ bankAccount.currency ? bankAccount.currency.symbol : ''}}
                </td>

                <td v-if="columns.number.show">
                    {{ bankAccount.number }}
                </td>

                <td v-if="columns.is_active.show">
                    {{ bankAccount.is_active ? $t('message.active') : $t('message.inactive') }}
                </td>

                <td v-if="columns.mfo.show">
                    {{ bankAccount.mfo }}
                </td>

                <td v-if="columns.created_at.show">
                    {{ bankAccount.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ bankAccount.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                     
                     <crm-settings
                        :name="$options.name"
                        :model="bankAccount"
                        :actions="actions"
                        :permissionShow="'bankAccounts.update'"
                        :permissionDestroy="'bankAccounts.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="50%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="50%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions, mapState } from "vuex";
import selectCurrency from "@/components/selects/select-currency"

export default {
    name: "bankAccounts",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        selectCurrency
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "bankAccounts/list",
            total_amount: "bankAccounts/total_amount",
            total_outgoing: "bankAccounts/total_outgoing",
            columns: "bankAccounts/columns",
            pagination: "bankAccounts/pagination",            
            filter: "bankAccounts/filter",
            sort: "bankAccounts/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "bankAccounts/index",
            setPagination: "bankAccounts/setPagination",
            updateSort: "bankAccounts/updateSort",
            updateFilter: "bankAccounts/updateFilter",
            updateColumn: "bankAccounts/updateColumn",
            updatePagination: "bankAccounts/updatePagination",
            show: "bankAccounts/show",
            empty: "bankAccounts/empty",
            delete: "bankAccounts/destroy",
            refreshData: "bankAccounts/refreshData",
        }),
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('bankAccounts/EMPTY_LIST');
        next()
    },
};
</script>

<style>
    .custom-font-size-16{
        font-size: 16px;
    }
    .clor-red-on-dept{
        color: rgb(235, 72, 112);
    }
    .bold__money{
        font-weight: bolder;
        font-size: 13px;
    }
</style>